import * as React from "react"
import { useCallback, useEffect, useState } from "react";
import NavBar from "../components/navbar";
import "../styles.css";
import Button from '@atlaskit/button';
import {
  Content,
  PageLayout,
  TopNavigation,
} from '@atlaskit/page-layout';
import authService from "../services/authService";
import { observer } from "mobx-react-lite";
import Radium from 'radium';
import BottomBar from "../components/bottomBar";
import PageHeader from "@atlaskit/page-header";



const Docs = () => {

  useEffect(() => {
    const initialize = async () => {
      if (await authService.getAccessTokenFromRefresh()) {
        await authService.getUserFromBitbucket();
      }
    }
    initialize();    
  }, []);


  return (
    <PageLayout>
        <TopNavigation
            testId="topNavigation"
            id="product-navigation"
            skipLinkTitle="Product Navigation"
            height={60}
            isFixed={false}
        >
          <NavBar/>
        </TopNavigation>
        <Content testId="content">
          <div style={{ display: "flex", flexDirection: "column",  }}>
            <div style={{ display: "flex", flexDirection: "row", maxWidth: 1200, margin: "auto", alignItems: "start", justifyContent: "start", }}>
                <div style={{ flex: 2, padding: 20, marginTop: 50, marginLeft: 20, marginRight: 20, marginBottom: 25, }}></div>
                <div style={{ position: "fixed", flex: 1, padding: 20, marginTop: 50, marginLeft: 20, marginRight: 20, marginBottom: 25, borderRadius: 10, display: "flex", flexDirection: "column" }}>
                    <span style={{ textAlign:"start", minWidth: 250, width: "60%", color: "#a5a8ad", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                        Installation
                    </span>
                    <div style={{ height: 5 }}></div>
                    <Button appearance="link" style={{ alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", marginLeft: 5, marginRight: 5 }} onClick={() => console.log("CLICK")}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", }}>
                            <span style={{ color: "#42526E" }}>Install Autocompress</span>
                        </div>
                    </Button>
                    <Button appearance="link" style={{ marginLeft: 5, marginRight: 5 }} onClick={() => console.log("CLICK")}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", }}>
                            <span style={{ color: "#42526E" }}>Log in using your Bitbucket account</span>
                        </div>
                    </Button>
                    <div style={{ height: 15 }}></div>
                    <span style={{ textAlign:"start", minWidth: 250, width: "60%", color: "#a5a8ad", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                        Subscription
                    </span>
                    <div style={{ height: 5 }}></div>
                    <Button appearance="link" style={{ marginLeft: 5, marginRight: 5 }} onClick={() => console.log("CLICK")}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", }}>
                            <span style={{ color: "#42526E" }}>Select a plan</span>
                        </div>
                    </Button>
                    <Button appearance="link" style={{ marginLeft: 5, marginRight: 5 }} onClick={() => console.log("CLICK")}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", }}>
                            <span style={{ color: "#42526E" }}>Manage your subscriptions</span>
                        </div>
                    </Button>
                    <div style={{ height: 15 }}></div>
                    <span style={{ textAlign:"start", minWidth: 250, width: "60%", color: "#a5a8ad", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                        Compression
                    </span>
                    <div style={{ height: 5 }}></div>
                    <Button appearance="link" style={{ marginLeft: 5, marginRight: 5 }} onClick={() => console.log("CLICK")}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", }}>
                            <span style={{ color: "#42526E" }}>How the whole compression flow works</span>
                        </div>
                    </Button>
                    <Button appearance="link" style={{ marginLeft: 5, marginRight: 5 }} onClick={() => console.log("CLICK")}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", }}>
                            <span style={{ color: "#42526E" }}>Compress a repo for the first time</span>
                        </div>
                    </Button>
                    <Button appearance="link" style={{ marginLeft: 5, marginRight: 5 }} onClick={() => console.log("CLICK")}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", }}>
                            <span style={{ color: "#42526E" }}>Stop compressing a repository</span>
                        </div>
                    </Button>
                    <Button appearance="link" style={{ marginLeft: 5, marginRight: 5 }} onClick={() => console.log("CLICK")}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", }}>
                            <span style={{ color: "#42526E" }}>Change the target branch</span>
                        </div>
                    </Button>
                    <Button appearance="link" style={{ marginLeft: 5, marginRight: 5 }} onClick={() => console.log("CLICK")}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "start", alignContent:"start", justifyItems: "start", justifyContent:"start", }}>
                            <span style={{ color: "#42526E" }}>Change the compression algorithm</span>
                        </div>
                    </Button>
                </div>
                <div style={{ width: 20 }}></div>
            <div style={{
                marginTop: 50,
              display: "flex",
              flexDirection: "column",
              flex: 5,
            }}>
                <h1 style={{ textAlign:"start", color: "#253858", fontWeight: "bold", fontSize: 35 }}>Documentation</h1>
                <PageHeader >
                    Install Autocompress
                </PageHeader>
                <span style={{ textAlign:"start", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                    Autocompress is a Bitbucket add-on and as such it needs to be installed on a Bitbucket workspace. To use Autocompress on more than one workspace, you will need to install and subscribe separately with each workspace. (You can use the free version for public repos simply by installing, no need to pay a subscription).
                    {/* <img src={heroImage} style={{ width: "98%"}}></img> */}
                    <br/><br/>You can install Autocompress in different ways:

                    <br/>- From Autocompress landing page, hitting the “Install on Bitbucket” button.
                    <br/>- From Bitbucket Marketplace listing of Autocompress, by clicking on the “Get it now” button.
                    <br/>- From the Autocompress home screen, using the “Add a new workspace” button at the bottom of the page.
                </span>
                

                <PageHeader >
                    Log in using your Bitbucket account
                </PageHeader>
                <span style={{ textAlign:"start", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                    To improve security and ease of use, Autocompress does not store credentials of any kind (email, password, etc.). Instead it relies on your Bitbucket account for authentication. All you have to do to sign in is open Autocompress from the “Open” button on the top bar and grant access to your account once you’ve been redirected to Bitbucket. Autocompress will remember your Bitbucket account and log you in immediately next time.

                    <br/><br/>To log out, simply click on your avatar on the top bar and then select “Log out”.
                </span>
                <PageHeader >
                    Select a plan
                </PageHeader>
                <span style={{ textAlign:"start", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                    Subscriptions are tied to Bitbucket workspaces. Each workspace requires its own subscription and allows a fixed number of private repositories to be compressed. Public repositories are always free so you don’t need a subscription to use Autocompress on open source projects and other public work.

                    <br/><br/>You can choose a subscription from the Pricing page, which you can find on the top bar. Click on one of the plans to subscribe.

                    <br/><br/>If you haven’t yet installed Autocompress on any workspace, clicking on a plan will trigger the installation flow before you’re redirected to subscribe.

                    <br/><br/>If you installed Autocompress on one workspace, clicking on a plan will upgrade or downgrade the subscription for that plan.

                    <br/><br/>If you have installed more than one workspace, you will be asked which workspace would you like to subscribe for.
                </span>
                <PageHeader >
                    Manage your subscriptions
                </PageHeader>
                <span style={{ textAlign:"start", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                    After subscribing with at least one workspace, you can always change your subscriptions by clicking on your avatar on the top bar and then selecting “Manage subscriptions”. On this screen you can Cancel, Update Payment Details and Upgrade your subscription. The “Upgrade” button will also allow you to downgrade the subscription to a lower plan if desired.

                    <br/><br/>Alternatively you can also upgrade or downgrade the subscription for a specific workspace from the home screen.
                </span>
                <PageHeader >
                    How the whole compression flow works
                </PageHeader>
                <span style={{ textAlign:"start", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                    Autocompress works like a Git bot, it listens to one of the branches of your repository and detects images (jpg, png, gif, svg, webp) being pushed to that branch.
                    <br/><br/>Whenever an image is added or modified, Autocompress creates a temporary clone of it and attempts to compress it following your compression settings (lossless or lossy). For each image type, it will iterate over several image optimization libraries and algorithms, comparing the results of each one and selecting only the smallest result.
                    <br/><br/>After the image is optimized, Autocompress creates (or reuses) its own branch on your repository, called “autocompress” for simplicity. It pushes the newly compressed image to this branch without deleting any previously compressed images. And lastly it creates a Pull Request with all these compressed images and a stats table for last compression run.

                    <br/><br/>Do not forget to merge the PR if you want to use the optimized images!
                </span>
                <PageHeader >
                    Compress a repository for the first time
                </PageHeader>
                <span style={{ textAlign:"start", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                    In order to trigger the compression flow outlined above, you need to activate the desired repository from the Autocompress home screen. To do this, simply click on the toggle button at the right of your repository.

                    <br/><br/>This will trigger a popup explaining that Autocompress will now attempt to optimize ALL the images in the repository, and showing you what setting you have selected (target branch and compression algorithm). Feel free to close the popup and change these settings if needed. If the settings look right to you, go ahead and press “Start compression”.

                    <br/><br/>Have in mind that since this first run compresses all of your images, it might take longer to complete than a normal run. You can repeat this process any time you want to compress the whole repository in one go.
                </span>
                <PageHeader >
                    Stop compressing a repository
                </PageHeader>
                <span style={{ textAlign:"start", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                    To stop listening to and compressing a repository, simply click the toggle at the right of an active repository. This will immediately deactivate Autocompress for that repo. Have in mind that the next time you activate the repository, Autocompress will attempt to optimize all of the images in it.  
                </span>
                <PageHeader >
                    Change the target branch
                </PageHeader>
                <span style={{ textAlign:"start", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                    Autocompress listens by default to your main branch (usually the “master” branch), which means it will only compress images pushed to this branch and will only create PRs for it as well. If you’d like Autocompress to target a different branch in your repo, simply expand the repository settings in the home screen and select the branch you’d like to compress under the “Target branch” menu. The changes are immediate, no need to save them.
                </span>
                <PageHeader >
                    Change the compression algorithm
                </PageHeader>
                <span style={{ textAlign:"start", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                Autocompress uses a lossless compression algorithm by default.
                <br/><br/>Lossless optimization attempts to compress images in a way that sacrifices no image quality at all. This usually results in bigger image sizes than lossy algorithms would give you, but you don’t lose any detail from the image. Alternatively, a lossy algorithm sacrifices some of this quality in order to obtain much smaller sizes. Autocompress lossy algorithms are all set to lose only up to ~20% image quality, which in most cases would be imperceptible to the naked eye.

                <br/><br/>You can change this setting at any point by expanding the repository settings in Autocompress home screen and selecting lossy or lossless under the “Compression method” menu. The changes are immediate, no need to save them.                </span>
            <div style={{ height: 30 }}></div>
                
            </div>
            </div>

            <BottomBar/>
            </div>
        </Content>
      </PageLayout>
  );
}

export default observer(Radium(Docs));